<template>
  <main class="register_complete">
    <img src="@/assets/img/register-complete@3x.png" alt="회원가입 완료" />
    <div>
      <h1>
        <span>{{ coporationName }}</span> 담당자님!
      </h1>
      <h1>스탬프 팝 사업자 <span>회원가입이 완료</span><br />되었습니다.</h1>
    </div>
    <p>
      다음으로 진행하여 스탬프 팝 페스티벌<br />
      신청을 완료해주세요.
    </p>
    <div class="btn_center">
      <base-button
        type="primary"
        @click="$router.push({ name: 'FestivalOrderProduct' })"
        >스탬프 팝 페스티벌 시작하기</base-button
      >
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
export default {
  components: { BaseButton },
  data() {
    return {
      coporationName: "",
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    fetch() {
      const userInfo = JSON.parse(localStorage.getItem("info"));
      this.coporationName = userInfo.id_user.corporation.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
